import '../../../dist/azure-maps-leaflet.js';
import './MapObject.scss';
import '../../../scss/variables.scss';
import '../../../dist/Leaflet.ImageOverlay.Rotated.js';
import 'leaflet-path-drag';
import 'leaflet-path-transform';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.markercluster';
import { lowercase } from '@utils';
import * as L from 'leaflet';
import { lowerCase } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { MapTourStartMenu } from '../../../components/layout/SonarMapTour/MapTourStartMenu';
import { ThemeContext } from '../../../context/ThemeContext';
import { MapControls } from './MapControls';
import { MapEditMenu } from './MapEditMenu/MapEditMenu';
import {
  mapGatewayOfflineIcon,
  mapGatewayOnlineIcon,
  mapNewPointIcon,
  mapStationaryOfflineIcon,
  mapStationaryOnlineIcon,
} from './MapIcons';
import { MapInfoMenu } from './MapInfoMenu/MapInfoMenu';
import { ObjectPopup } from './ObjectPopup';

interface MapObjectProps {
  app: any;
  mapId: string;
  mapKey: string;
  urlArr: string[];
  mapImg: any;
  overlayData: any;
  placeData: any;
  gatewayData: any;
  assetData: any;
  stationaryData: any;
  placeList: any;
  gatewayList: any;
  assetList: any;
  stationaryList: any;
  updateMapFC: any;
  updatePlaceFC: any;
  updateGatewayFC: any;
  updateStationaryFC: any;
  updateScale: any;
  resetAsset: () => void;
  resetPlace: () => void;
  resetGateway: () => void;
  resetStationary: () => void;
  fetchAssetData: (any) => void;
  fetchPlaceData: (any) => void;
  fetchGatewayData: (any) => void;
  fetchStationaryData: (any) => void;
  setMapImg: (string) => void;
  saveMap: () => void;
  reset: () => void;
}

export function MapObject({
  app,
  mapId,
  mapKey,
  urlArr,
  mapImg,
  overlayData,
  placeData,
  gatewayData,
  assetData,
  stationaryData,
  placeList,
  gatewayList,
  assetList,
  stationaryList,
  updateMapFC,
  updatePlaceFC,
  updateGatewayFC,
  updateStationaryFC,
  updateScale,
  resetAsset,
  resetPlace,
  resetGateway,
  resetStationary,
  fetchAssetData,
  fetchPlaceData,
  fetchGatewayData,
  fetchStationaryData,
  setMapImg,
  saveMap,
  reset,
}: MapObjectProps) {
  // Map References
  const mapRef = useRef(null);
  const azureTileRef = useRef(null);
  const imageRef = useRef(null);
  const mapGeoRef = useRef(null);
  const tempRef = useRef(null);
  const placeRef = useRef(null);
  const placeDrawRef = useRef(null);
  const gatewayRef = useRef(null);
  const gatewayDrawRef = useRef(null);
  const stationaryRef = useRef(null);
  const stationaryDrawRef = useRef(null);
  const scaleDrawRef = useRef(null);
  const assetRef = useRef(null);
  const mapGeoJSON = overlayData.features[0];
  const isStatic = mapGeoJSON.properties.MapType === 'Static';
  const { theme } = useContext(ThemeContext);
  const history = useHistory();

  // Map controls states
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(urlArr.includes('edit') ? true : false);
  const [selected, setSelected] = useState<string>('asset');
  const [reading, setReading] = useState<any>('temperature');
  const [mapLayers, setMapLayers] = useState<any>(['assets']);

  // Map General Info

  const [bounds, setBounds] = useState<any>(null);
  const [mapName, setMapName] = useState<string>(overlayData.features[0].properties.Name);
  const [mapKeywords, setMapKeywords] = useState<[]>(overlayData.features[0].properties.Keywords);
  const [mapDescription, setMapDescription] = useState<string>(
    overlayData.features[0].properties.Description,
  );

  // Map Layers
  const [mapInstance, setMapInstance] = useState<any>(null);
  const [azureLayer, setAzureLayer] = useState<any>(null);
  const [imageLayer, setImageLayer] = useState<any>(null);
  const [boundsLayer, setBoundsLayer] = useState<any>(null);
  const [placeLayer, setPlaceLayer] = useState<any>(null);
  const [placeDrawLayer, setPlaceDrawLayer] = useState<any>(null);
  const [gatewayLayer, setGatewayLayer] = useState<any>(null);
  const [gatewayDrawLayer, setGatewayDrawLayer] = useState<any>(null);
  const [stationaryLayer, setStationaryLayer] = useState<any>(null);
  const [stationaryDrawLayer, setStationaryDrawLayer] = useState<any>(null);
  const [scaleDrawLayer, setScaleDrawLayer] = useState<any>(null);
  const [scaleLayer, setScaleLayer] = useState<any>(null);
  const [assetLayer, setAssetLayer] = useState<any>(null);
  const [tempLayer, setTempLayer] = useState<any>(null);

  // Map Events
  const [drawEvent, setDrawEvent] = useState<any>(null);
  const [editEvent, setEditEvent] = useState<any>(null);
  const [clickEvent, setClickEvent] = useState<any>(null);
  const [vertexEvent, setVertexEvent] = useState<any>(null);
  const [mapUpdated, setMapUpdated] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<any>(null);

  // Object Management States
  const [createPlace, setCreatePlace] = useState<boolean>(false);
  const [createGateway, setCreateGateway] = useState<boolean>(false);
  const [createStationary, setCreateStationary] = useState<boolean>(false);
  const [createScale, setCreateScale] = useState<boolean>(false);
  const [scaleLength, setScaleLength] = useState<string>('');
  const [newObjId, setNewObjId] = useState<string>('');
  const [newObjName, setNewObjName] = useState<string>('');
  const [newObjKeywords, setNewObjKeywords] = useState<any>([]);
  const [newObjFloor, setNewObjFloor] = useState<string>('');
  const [newObjOnline, setNewObjOnline] = useState<boolean>(null);
  const [newObjCreated, setNewObjCreated] = useState<any>(null);
  const [newScaleCreated, setNewScaleCreated] = useState<any>(null);
  const [addToMap, setAddToMap] = useState<boolean>(false);
  const [addScale, setAddScale] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [updateItem, setUpdateItem] = useState<boolean>(false);
  const [removeTempItem, setRemoveTempItem] = useState<boolean>(false);

  const menuRef = useRef(null);

  // Azure authentication
  const authOptions = {
    authType: 'subscriptionKey',
    subscriptionKey: mapKey,
  };

  // Azure tile overlay
  azureTileRef.current = L.tileLayer.azureMaps({
    authOptions: authOptions,
    maxNativeZoom: 18,
    maxZoom: 25,
    minZoom: 2,
    tilesetId: theme === 'dark' ? 'microsoft.base.darkgrey' : 'microsoft.base.road',
    crossOrigin: 'anonymous',
  });

  // GeoJSON object for map overlay
  mapGeoRef.current = L.geoJSON(mapGeoJSON, {
    style: { color: 'var(--secondary)', fillColor: '#ffffff00', weight: 1 },
    draggable: true,
    transform: true,
  });

  // Image overlay tied to map GeoJSON object
  imageRef.current = L.imageOverlay.rotated(
    mapImg,
    translateCoords(3, mapGeoJSON.geometry.coordinates[0]),
    translateCoords(2, mapGeoJSON.geometry.coordinates[0]),
    translateCoords(0, mapGeoJSON.geometry.coordinates[0]),
  );

  // Helper to detect overlap and pan if necessary
  function handlePopupAndMenuOverlap(layer) {
    try {
      const map = layer._map;
      let latLng = layer.getLatLng
        ? layer.getLatLng() // For markers
        : layer.getBounds().getCenter(); // For polygons
      // Adjust the popup position to avoid overlap
      const newLatLng = map.project(latLng);
      const adjustedLatLng = map.unproject([newLatLng.x + 250, newLatLng.y]);
      // Smooth pan to the adjusted location
      map.setView(adjustedLatLng, map.getZoom(), { animate: true });
    } catch (e) {
      console.log(layer);
    }
  }

  // Temp feature group and controls
  tempRef.current = new L.FeatureGroup({});

  // Place feature group and controls
  placeRef.current = L.featureGroup();

  useEffect(() => {
    // Clear out old places
    if (placeLayer?._layers) {
      placeLayer.eachLayer((layer) => {
        placeLayer.removeLayer(layer);
      });
    }

    // Set up polygons for filtered places
    let curPlaces = new L.geoJSON(placeData, {
      onEachFeature(feature, layer) {
        layer.bindPopup(
          ReactDOMServer.renderToString(<ObjectPopup properties={feature.properties} />),
        );

        layer.on('click', (e) => {
          setSelected('place');
          setClickEvent(e.sourceTarget);
          handlePopupAndMenuOverlap(layer); // Add this to handle overlap

          document
            .getElementById(`copy-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              navigator.clipboard.writeText(e.sourceTarget.feature.properties.Name);
            });
          document
            .getElementById(`more-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              setSelected('place');
              setShowInfo(false);
              setShowMore(e.sourceTarget.feature);
              setClickEvent(e.sourceTarget);
            });
        });
      },
      style: () => {
        return { color: 'var(--c-primary)', fillColor: '#ffffff00', weight: 2, opacity: 1 };
      },
    });

    // Add places to group
    if (placeLayer) {
      let newPlaces = Object.create(placeLayer);
      curPlaces.eachLayer((layer) => {
        newPlaces.addLayer(layer);
      });
      setPlaceLayer(newPlaces);
    }
  }, [placeData]);

  // Gateway feature group and controls
  gatewayRef.current = L.featureGroup();

  useEffect(() => {
    // Clear existing layers
    if (gatewayLayer?._layers) {
      gatewayLayer.eachLayer((layer) => gatewayLayer.removeLayer(layer));
    }

    // Initialize the GeoJSON with click handling
    let curGateways = new L.geoJSON(gatewayData, {
      onEachFeature(feature, layer) {
        layer.bindPopup(
          ReactDOMServer.renderToString(<ObjectPopup properties={feature.properties} />),
        );

        // Open the popup and handle the overlap with menu
        layer.on('click', (e) => {
          setSelected('gateway');
          handlePopupAndMenuOverlap(layer);
          setClickEvent(e.sourceTarget);

          document
            .getElementById(`copy-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              navigator.clipboard.writeText(e.sourceTarget.feature.properties.Name);
            });
          document
            .getElementById(`more-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              setSelected('gateway');
              setShowInfo(false);
              setShowMore(e.sourceTarget.feature);
              setClickEvent(e.sourceTarget);
            });
        });
      },
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          icon:
            feature.properties.OnlineStatus === 'Online'
              ? mapGatewayOnlineIcon
              : mapGatewayOfflineIcon,
        }),
    });

    // Add to gateway layer
    if (gatewayLayer) {
      let newGateways = Object.create(gatewayLayer);
      curGateways.eachLayer((layer) => newGateways.addLayer(layer));
      setGatewayLayer(newGateways);
    }
  }, [gatewayData]);

  // Asset feature group and controls
  assetRef.current = L.markerClusterGroup({
    showCoverageOnHover: false,
    spiderfyDistanceMultiplier: 3,
    maxClusterRadius: 40,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        html: `<div style='background-color: var(--c-primary);' class='marker-pin'></div><b>${cluster.getChildCount()}</b>`,
        iconSize: [30, 42], // size of the icon
        iconAnchor: [15, 42], // point of the icon which will correspond to marker's location
        popupAnchor: [-1, -12],
        className: 'custom-div-icon',
      });
    },
  });

  useEffect(() => {
    // Clear out old assets
    if (assetLayer?._featureGroup._layers) {
      assetLayer.eachLayer((layer) => {
        assetLayer.removeLayer(layer);
      });
    }

    // Set up markers for filtered assets
    let curAssets = new L.geoJSON(assetData, {
      onEachFeature(feature, layer) {
        layer.bindPopup(
          ReactDOMServer.renderToString(<ObjectPopup properties={feature.properties} />),
        );

        // Handle popup and menu overlap here
        layer.on('click', (e) => {
          setSelected('asset');
          handlePopupAndMenuOverlap(layer);
          setClickEvent(e.sourceTarget);

          document
            .getElementById(`copy-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              navigator.clipboard.writeText(e.sourceTarget.feature.properties.Name);
            });

          document
            .getElementById(`go-to-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              history.push('/assets/' + e.sourceTarget.feature.properties.Id);
            });

          document
            .getElementById(`more-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              setSelected('asset');
              setShowInfo(false);
              setShowMore(e.sourceTarget.feature);
              setClickEvent(e.sourceTarget);
            });
        });
      },
      pointToLayer: function (feature, latlng) {
        return L.circle(latlng, {
          radius: 0.25,
          color:
            feature.properties.OnlineStatus === 'Online'
              ? 'var(--c-primary)'
              : feature.properties.OnlineStatus === 'Offline'
              ? 'var(--danger)'
              : 'var(--alt1)',
          fillColor:
            feature.properties.OnlineStatus === 'Online'
              ? 'var(--c-primary)'
              : feature.properties.OnlineStatus === 'Offline'
              ? 'var(--danger)'
              : 'var(--alt1)',
          fillOpacity: 0.5,
          weight: 1,
        });
      },
    });

    // Add assets to the asset layer
    if (assetLayer) {
      let newAssets = Object.create(assetLayer);
      curAssets.eachLayer((layer) => {
        newAssets.addLayer(layer);
      });
      setAssetLayer(newAssets);
    }
  }, [assetData]);

  // Stationary feature group and controls
  stationaryRef.current = L.featureGroup();

  useEffect(() => {
    // Clear out old stationary assets
    if (stationaryLayer?._layers) {
      stationaryLayer.eachLayer((layer) => {
        stationaryLayer.removeLayer(layer);
      });
    }

    let curStationary = new L.geoJSON(stationaryData, {
      onEachFeature(feature, layer) {
        layer.bindPopup(
          ReactDOMServer.renderToString(<ObjectPopup properties={feature.properties} />),
        );

        // Handle popup and menu overlap for stationary assets
        layer.on('click', (e) => {
          setSelected('stationary');
          handlePopupAndMenuOverlap(layer);
          setClickEvent(e.sourceTarget);

          document
            .getElementById(`copy-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              navigator.clipboard.writeText(e.sourceTarget.feature.properties.Name);
            });

          document
            .getElementById(`go-to-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              history.push('/assets/' + e.sourceTarget.feature.properties.Id);
            });

          document
            .getElementById(`more-btn-${feature.properties.Id}`)
            ?.addEventListener('click', () => {
              setSelected('stationary');
              setShowInfo(false);
              setShowMore(e.sourceTarget.feature);
              setClickEvent(e.sourceTarget);
            });
        });
      },
      pointToLayer: function (feature, latlng) {
        return L.marker(latlng, {
          icon:
            feature.properties.OnlineStatus === 'Online'
              ? mapStationaryOnlineIcon
              : mapStationaryOfflineIcon,
        });
      },
    });

    // Add stationary assets to the stationary layer
    if (stationaryLayer) {
      let newStationary = Object.create(stationaryLayer);
      curStationary.eachLayer((layer) => {
        newStationary.addLayer(layer);
      });
      setStationaryLayer(newStationary);
    }
  }, [stationaryData]);

  // Initial Map Setup
  useEffect(() => {
    mapRef.current = L.map('myMap', {
      attributionControl: false,
      maxBoundsViscosity: 1.0,
      maxZoom: 25,
    });
    if (!isStatic) {
      mapRef.current.addLayer(azureTileRef.current);
    }
    mapRef.current.addLayer(imageRef.current);
    mapRef.current.addLayer(mapGeoRef.current);
    mapRef.current.addLayer(tempRef.current);
    mapRef.current.addLayer(placeRef.current);
    mapRef.current.addLayer(gatewayRef.current);
    mapRef.current.addLayer(stationaryRef.current);
    mapRef.current.addLayer(assetRef.current);
    let mapBounds = mapGeoRef.current.getBounds();
    setBounds(mapBounds);

    // Set full map bounds
    const bounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));
    mapRef.current.setMaxBounds(bounds);

    mapRef.current.fitBounds(mapBounds);

    placeDrawRef.current = new L.Draw.Polygon(mapRef.current, {
      shapeOptions: { color: 'var(--primary)', fillColor: '#ffffff00', weight: 2, opacity: 1 },
    });
    gatewayDrawRef.current = new L.Draw.Marker(mapRef.current, {
      icon: mapNewPointIcon,
    });
    stationaryDrawRef.current = new L.Draw.Marker(mapRef.current, {
      icon: mapNewPointIcon,
    });
    scaleDrawRef.current = new L.Draw.Polyline(mapRef.current, {
      shapeOptions: {
        color: 'var(--primary)',
        fillColor: '#ffffff00',
        weight: 2,
        opacity: 1,
      },
    });

    // Listen for draw events
    mapRef.current.on(L.Draw.Event.CREATED, (e) => {
      setDrawEvent(e.layer);
    });

    // Listen for draw vertex
    mapRef.current.on(L.Draw.Event.DRAWVERTEX, (e) => {
      setVertexEvent(e);
    });

    // Listen for drag events
    mapRef.current.on(L.Draw.Event.EDITMOVE, (e) => {
      setEditEvent(e.layer);
    });

    // Listen for vertex events
    mapRef.current.on(L.Draw.Event.EDITVERTEX, (e) => {
      setEditEvent(e.poly);
    });

    setMapInstance(mapRef.current);
    if (mapGeoJSON.properties.MapType !== 'Static') {
      setAzureLayer(azureTileRef.current);
    }
    setBoundsLayer(mapGeoRef.current);
    setImageLayer(imageRef.current);
    setTempLayer(tempRef.current);
    setPlaceLayer(placeRef.current);
    setPlaceDrawLayer(placeDrawRef.current);
    setGatewayLayer(gatewayRef.current);
    setGatewayDrawLayer(gatewayDrawRef.current);
    setStationaryLayer(stationaryRef.current);
    setStationaryDrawLayer(stationaryDrawRef.current);
    setScaleDrawLayer(scaleDrawRef.current);
    setAssetLayer(assetRef.current);
    // eslint-disable-next-line
  }, []);

  // Toggle Map Edit
  useEffect(() => {
    if (urlArr.includes('edit')) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [urlArr]);

  // Save new object to correct FG on user save
  useEffect(() => {
    if (addToMap) {
      tempLayer.removeLayer(drawEvent);
      let feature = (drawEvent.feature = drawEvent.feature || {}); // Intialize layer.feature
      feature.type = feature.type || 'Feature'; // Intialize feature.type
      let props = (feature.properties = feature.properties || {}); // Intialize feature.properties
      props.Id = newObjId;
      props.Name = newObjName;
      props.Keywords = newObjKeywords;
      props.Floor = newObjFloor;
      switch (selected) {
        case 'place':
          props.EntityType = 'Place';
          placeLayer.addLayer(drawEvent);
          break;
        case 'gateway':
          props.EntityType = 'Gateway';
          gatewayLayer.addLayer(drawEvent);
          break;
        case 'stationary':
          props.EntityType = 'Stationary';
          stationaryLayer.addLayer(drawEvent);
          break;
      }
      setMapUpdated(true);
      setRemoveTempItem(true);
    }
  }, [
    addToMap,
    selected,
    newObjId,
    newObjName,
    newObjKeywords,
    newObjFloor,
    drawEvent,
    tempLayer,
    placeLayer,
    gatewayLayer,
    stationaryLayer,
    setRemoveTempItem,
  ]);

  // Build payload to update scale
  useEffect(() => {
    if (addScale && newScaleCreated !== null) {
      const scaleFC = newScaleCreated.layers.toGeoJSON();
      let points = [];
      Object.values(scaleFC.features).forEach((item) => points.push((item as any).geometry));
      let newScaleObj = { points: points, feet: parseFloat(scaleLength) };
      setScaleLayer(newScaleObj);
      setDrawEvent(null);
      setCreateScale(false);
      setVertexEvent(null);
      setNewObjCreated(null);
      setMapUpdated(true);
    }
  }, [addScale, scaleLength, newScaleCreated]);

  // Limits scale drawing to two points
  useEffect(() => {
    if (createScale && vertexEvent) {
      const layerIds = Object.keys(vertexEvent.layers._layers);
      if (layerIds.length > 1) {
        const secondVertex = vertexEvent.layers._layers[layerIds[1]]._icon;
        requestAnimationFrame(() => secondVertex.click());
        setNewScaleCreated(vertexEvent);
      }
    }
  }, [vertexEvent, createScale]);

  // Save existing object to correct FG on user save
  useEffect(() => {
    if (updateItem && editEvent) {
      if (editEvent.feature.properties.EntityType === 'Place') {
        placeLayer.addLayer(editEvent);
      }
      if (editEvent.feature.properties.EntityType === 'Gateway') {
        gatewayLayer.addLayer(editEvent);
      }
      if (editEvent.feature.properties.EntityType === 'Stationary Asset') {
        stationaryLayer.addLayer(editEvent);
      }
      setMapUpdated(true);
      setEditEvent(null);
      setUpdateItem(false);
    }
  }, [editEvent, updateItem, placeLayer, gatewayLayer, stationaryLayer]);

  // Delete existing object from correct FG on user save
  useEffect(() => {
    if (deleteItem && clickEvent) {
      if (clickEvent.feature.properties.EntityType === 'Place') {
        placeLayer.removeLayer(clickEvent);
      }
      if (clickEvent.feature.properties.EntityType === 'Gateway') {
        gatewayLayer.removeLayer(clickEvent);
      }
      if (clickEvent.feature.properties.EntityType === 'Stationary Asset') {
        stationaryLayer.removeLayer(clickEvent);
      }
      setMapUpdated(true);
      setClickEvent(null);
      setDeleteItem(null);
      setUpdateItem(false);
    }
  }, [deleteItem, clickEvent, placeLayer, gatewayLayer, stationaryLayer, saveMap]);

  // Add most recent draw event to temp feature group
  useEffect(() => {
    if (drawEvent) {
      if (!createScale) {
        let feature = (drawEvent.feature = drawEvent.feature || {}); // Intialize layer.feature
        feature.type = feature.type || 'Feature'; // Intialize feature.type
        let props = (feature.properties = feature.properties || {}); // Intialize feature.properties
        props.Id = newObjId;
        props.Name = newObjName;
        props.Keywords = newObjKeywords;
        props.Floor = newObjFloor;
        props.OnlineStatus = newObjOnline;
        setNewObjCreated(drawEvent);
      }
      tempLayer.addLayer(drawEvent);
    }
  }, [
    createScale,
    drawEvent,
    selected,
    newObjId,
    newObjName,
    newObjKeywords,
    newObjFloor,
    newObjOnline,
    tempLayer,
  ]);

  // Reset tempFG and properties
  useEffect(() => {
    if (removeTempItem) {
      let tempLayers = tempLayer.getLayers();
      tempLayers.forEach((layer) => {
        tempLayer.removeLayer(layer);
      });
      setDrawEvent(null);
      setNewObjId(null);
      setNewObjName(null);
      setNewObjKeywords(null);
      setNewObjFloor(null);
      setNewObjOnline(null);
      setNewObjCreated(null);
      setAddToMap(false);
      setRemoveTempItem(false);
    }
  }, [removeTempItem, tempLayer]);

  // Send data to wrapper
  useEffect(() => {
    if (mapUpdated) {
      updateGatewayFC(gatewayLayer.toGeoJSON());
      updatePlaceFC(placeLayer.toGeoJSON());
      updateStationaryFC(stationaryLayer.toGeoJSON());
      updateScale(scaleLayer);
      setMapUpdated(false);
    }
  }, [
    mapUpdated,
    placeLayer,
    gatewayLayer,
    stationaryLayer,
    scaleLayer,
    updatePlaceFC,
    updateGatewayFC,
    updateStationaryFC,
  ]);

  // Enable or disable drawing tools
  useEffect(() => {
    if (mapInstance) {
      createPlace && selected === 'place' ? placeDrawLayer.enable() : placeDrawLayer.disable();
      createGateway && selected === 'gateway'
        ? gatewayDrawLayer.enable()
        : gatewayDrawLayer.disable();
      createStationary && selected === 'stationary'
        ? stationaryDrawLayer.enable()
        : stationaryDrawLayer.disable();
      createScale && selected === 'overlay' ? scaleDrawLayer.enable() : scaleDrawLayer.disable();
    }
  }, [
    createPlace,
    createGateway,
    createStationary,
    createScale,
    selected,
    mapInstance,
    gatewayDrawLayer,
    placeDrawLayer,
    stationaryDrawLayer,
    scaleDrawLayer,
  ]);

  // Toggle layers
  useEffect(() => {
    const overlayOptions = {
      color: 'var(--secondary)',
      fillColor: '#ffffff00',
      weight: 1,
      dashArray: '0, 0',
    };

    // Toggle Bounds Edit
    if (mapInstance && boundsLayer && edit && selected === 'overlay' && !isStatic) {
      for (let item in boundsLayer._layers) {
        boundsLayer._layers[item].transform.enable({
          boundsOptions: overlayOptions,
          rotateHandleOptions: overlayOptions,
        });
        boundsLayer._layers[item].dragging.enable();
      }
    } else if (mapInstance && boundsLayer && (!edit || selected !== 'overlay')) {
      for (let item in boundsLayer._layers) {
        boundsLayer._layers[item].transform.disable();
        boundsLayer._layers[item].dragging.disable();
      }
    }

    // Toggle Azure Overlay
    if (azureLayer) {
      updateTileLayer(
        mapLayers.includes('sat')
          ? 'microsoft.imagery'
          : theme === 'dark'
          ? 'microsoft.base.darkgrey'
          : 'microsoft.base.road',
      );
    }

    // Toggle Bounds Outline
    if (boundsLayer) updateBoundsLayer(mapLayers.includes('edge') ? 1 : 0);

    // Toggle Gateways
    if (gatewayLayer) {
      if (mapInstance.hasLayer(gatewayLayer)) {
        if (!mapLayers.includes('gateways')) {
          if (selected !== 'gateway' && selected !== 'null') {
            gatewayLayer.remove();
          }
        }
      } else {
        if (mapLayers.includes('gateways') || selected === 'gateway')
          gatewayLayer.addTo(mapInstance);
      }
    }

    //Toggle Places
    if (placeLayer) {
      if (mapInstance.hasLayer(placeLayer)) {
        if (!mapLayers.includes('places')) {
          if (selected !== 'place' && selected !== 'null') {
            placeLayer.remove();
          }
        }
      } else {
        if (mapLayers.includes('places') || selected === 'place') placeLayer.addTo(mapInstance);
      }
    }

    //Toggle Assets
    if (assetLayer) {
      if (mapInstance.hasLayer(assetLayer)) {
        if (!mapLayers.includes('assets')) {
          if (selected !== 'asset' && selected !== 'null') {
            assetLayer.remove();
          }
        }
      } else {
        if (mapLayers.includes('assets') || selected === 'asset') assetLayer.addTo(mapInstance);
      }
    }

    //Toggle Stationary Assets
    if (stationaryLayer) {
      if (mapInstance.hasLayer(stationaryLayer)) {
        if (!mapLayers.includes('stationary assets')) {
          if (selected !== 'stationary' && selected !== 'null') {
            stationaryLayer.remove();
          }
        }
      } else {
        if (mapLayers.includes('stationary assets') || selected === 'stationary')
          stationaryLayer.addTo(mapInstance);
      }
    }
  }, [
    mapInstance,
    mapLayers,
    theme,
    edit,
    selected,
    boundsLayer,
    azureLayer,
    placeLayer,
    gatewayLayer,
    assetLayer,
    stationaryLayer,
    updateBoundsLayer,
    updateTileLayer,
  ]);

  useEffect(() => {
    // Edit map bounds
    if (edit && mapInstance && boundsLayer._layers) {
      for (let item in boundsLayer._layers) {
        boundsLayer._layers[item].on('transformed', () => {
          updateMapFC(boundsLayer.toGeoJSON());

          imageLayer.reposition(
            boundsLayer._layers[item]._latlngs[0][3],
            boundsLayer._layers[item]._latlngs[0][2],
            boundsLayer._layers[item]._latlngs[0][0],
          );
        });
      }
    }
  }, [edit, boundsLayer, imageLayer, mapInstance, updateMapFC]);

  // Turn of drawing control if tab is changed
  useEffect(() => {
    if (selected !== 'place') {
      setCreatePlace(false);
    }
    if (selected !== 'gateway') {
      setCreateGateway(false);
    }
    if (selected !== 'stationary') {
      setCreateStationary(false);
    }
    if (selected !== 'overlay') {
      setCreateScale(false);
    }
  }, [selected]);

  useEffect(() => {
    if (!(selected && showMore)) {
      setShowMore(null);
    }
    if (selected !== lowercase(showMore?.properties.EntityType).split(' ')[0]) {
      setShowMore(null);
    }
  }, [selected, showMore]);

  // Update map overaly feature collection
  useEffect(() => {
    let newFC = overlayData;
    newFC.features[0].properties.Name = mapName;
    newFC.features[0].properties.Description = mapDescription;
    newFC.features[0].properties.Keywords = mapKeywords;
    updateMapFC(newFC);
  }, [mapName, mapDescription, mapKeywords, overlayData, updateMapFC]);

  // Get clicked object
  useEffect(() => {
    if (clickEvent) {
      setNewObjId(clickEvent.feature.properties.Id);
      setNewObjName(clickEvent.feature.properties.Name);
      setNewObjKeywords(clickEvent.feature.properties.Keywords);
      setNewObjFloor(clickEvent.feature.properties.Floor);
    }
  }, [clickEvent]);

  // Update overlay opacity
  function updateImageOpacity(value) {
    imageLayer.setOpacity(parseInt(value) / 100);
  }

  // Update Azure tile layer
  function updateTileLayer(id) {
    azureLayer.setTilesetId(id);
  }

  // Update boundsLayer
  function updateBoundsLayer(border) {
    for (let item in boundsLayer._layers) {
      boundsLayer._layers[item].setStyle({ opacity: border });
    }
  }

  // Translate coords for map img overlay
  function translateCoords(i, coords) {
    let imgCorner = L.latLng(coords[i][1], coords[i][0]);
    return imgCorner;
  }

  return (
    <>
      <div
        id="myMap"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '1em',
          boxShadow: 'var(--box-shadow-right)',
        }}
      />
      {edit ? (
        <MapEditMenu
          id="menu"
          showInfo={showInfo}
          ref={menuRef}
          app={app}
          mapId={mapId}
          mapLayers={mapLayers}
          mapName={mapName}
          mapDescription={mapDescription}
          mapKeywords={mapKeywords}
          isStatic={isStatic}
          placeList={placeList}
          gatewayList={gatewayList}
          stationaryList={stationaryList}
          placeData={placeLayer}
          assetData={assetLayer}
          gatewayData={gatewayLayer}
          stationaryData={stationaryLayer}
          selected={selected}
          newObjCreated={newObjCreated}
          newScaleCreated={newScaleCreated}
          clickedItem={clickEvent}
          showMore={showMore}
          setShowInfo={(e) => setShowInfo(e)}
          setMapName={(e) => setMapName(e)}
          setMapKeywords={(e) => setMapKeywords(e)}
          setMapDescription={(e) => setMapDescription(e)}
          setMapImg={(e) => setMapImg(e)}
          setSelected={(e) => setSelected(e)}
          setCreatePlace={(e) => setCreatePlace(e)}
          setCreateGateway={(e) => setCreateGateway(e)}
          setCreateStationary={(e) => setCreateStationary(e)}
          setCreateScale={(e) => setCreateScale(e)}
          setAddScale={(e) => setAddScale(e)}
          setScaleLength={(e) => setScaleLength(e)}
          setObjectId={(e) => setNewObjId(e)}
          setObjectName={(e) => setNewObjName(e)}
          setObjectKeywords={(e) => setNewObjKeywords(e)}
          setObjectFloor={(e) => setNewObjFloor(e)}
          setObjectOnline={(e) => setNewObjOnline(e)}
          setAddToMap={(e) => setAddToMap(e)}
          setDeleteItem={(e) => setDeleteItem(e)}
          setUpdateItem={(e) => setUpdateItem(e)}
          setRemoveTempItem={(e) => setRemoveTempItem(e)}
          setClickedItem={(e) => setClickEvent(e)}
          fetchPlaceData={(e) => fetchPlaceData(e)}
          fetchGatewayData={(e) => fetchGatewayData(e)}
          fetchStationaryData={(e) => fetchStationaryData(e)}
          reset={() => reset()}
          saveMap={() => saveMap()}
          setShowMore={(e) => setShowMore(e)}
        />
      ) : (
        <>
          <MapInfoMenu
            id="menu"
            showInfo={showInfo}
            ref={menuRef}
            mapId={mapId}
            selected={selected}
            setSelected={(e) => setSelected(e)}
            mapData={overlayData}
            placeList={placeList}
            assetList={assetList}
            gatewayList={gatewayList}
            stationaryList={stationaryList}
            showMore={showMore}
            setShowInfo={(e) => setShowInfo(e)}
            reset={() => reset()}
            resetAsset={() => resetAsset()}
            resetPlace={() => resetPlace()}
            resetGateway={() => resetGateway()}
            resetStationary={() => resetStationary()}
            setShowMore={(e) => setShowMore(e)}
            fetchAssetData={(e) => fetchAssetData(e)}
            fetchPlaceData={(e) => fetchPlaceData(e)}
            fetchGatewayData={(e) => fetchGatewayData(e)}
            fetchStationaryData={(e) => fetchStationaryData(e)}
          />
          <MapTourStartMenu />
        </>
      )}
      <MapControls
        mapInstance={mapInstance}
        mapLayers={mapLayers}
        isStatic={!isStatic}
        mapEdit={edit}
        reading={reading}
        bounds={bounds}
        setMapLayers={setMapLayers}
        setReading={setReading}
        updateImageOpacity={updateImageOpacity}
      />
    </>
  );
}
